import React from "react";
import { css } from "@emotion/core";
import { Navigation, INavigationItem } from "./navigation";
import styled from "@emotion/styled";
import { colors } from "../../common/utilities";

const FooterWrapper = styled.footer({
  padding: 16,
  borderTop: `2px solid ${colors.grey.base}`,
});

interface IFooterProps {
  resumeUrl: string;
}

export const Footer: React.SFC<IFooterProps> = ({ resumeUrl }) => {
  const navigationItems: INavigationItem[] = [
    {
      name: "Phone: (951) 486-1929",
    },
    {
      name: "Email: Brayden.M.Stewart@gmail.com",
    },
    {
      name: "Download Resume",
      externalLink: resumeUrl,
    },
    {
      name: "View Source Code",
      link: "https://bitbucket.org/B-Stewart/portfolio-gatsby/",
    },
  ];

  return (
    <FooterWrapper>
      <Navigation items={navigationItems} />
    </FooterWrapper>
  );
};
