import React from "react";
import { css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import smallStars from "../../media/images/small-stars.png";
import medStars from "../../media/images/medium-stars.png";
import largeStars from "../../media/images/large-stars.png";

const fallingStars = keyframes`
  from {
    background-position: 0% 1300%
  }
  to { 
    background-position: 0% 0%
  }
`;

const risingStars = keyframes`
  from {
    background-position: 0% 0%
  }
  to { 
    background-position: 0% 1300%
  }
`;

// https://codepen.io/humblemeteor/pen/JJWEJE
interface IStars {
  height?: string | number;
  full?: boolean;
}
const Stars = styled.div<IStars>(({ height, full }) => ({
  overflow: "hidden",
  top: 0,
  left: 0,
  width: "100%",
  height: height,
  position: full ? "absolute" : "relative",
  bottom: full ? 0 : undefined,
}));

interface IStar {
  speed?: number;
  rise?: boolean;
}

const StarSmall = styled.div<IStar>(({ speed, rise }) => ({
  background: `url(${smallStars}) repeat`,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  opacity: 0.6,
  animation: `${rise ? risingStars : fallingStars} ${speed *
    0.75}s linear infinite`,
}));

const StarMedium = styled.div<IStar>(({ speed, rise }) => ({
  background: `url(${medStars}) repeat`,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  opacity: 0.4,
  animation: `${rise ? risingStars : fallingStars} ${speed}s linear infinite`,
}));

interface IFallingStarsProps extends IStars, IStar {}

export const FallingStars: React.SFC<IFallingStarsProps> = ({
  height,
  full,
  speed = 120,
  rise,
}) => (
  <Stars height={height} full={full}>
    <StarSmall speed={speed} rise={rise} />
    <StarMedium speed={speed} rise={rise} />
  </Stars>
);

FallingStars.displayName = "FallingStars";
