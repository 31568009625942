import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { FallingStars } from "../falling-stars";

export interface IHero {
  height: number;
}

const Hero = styled.div<IHero>(({ height }) => ({
  display: "inline-block",
  width: "100%",
  height: height,
  position: "relative",
}));

export interface IDiagonal {
  slant: number;
  height: number;
  backgroundColor: string;
  gradientColor: string;
}

const Diagonal = styled.div<IDiagonal>(
  ({ height, slant, backgroundColor, gradientColor }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: `100%`,
    height: height,
    zIndex: -1,
    backgroundColor: backgroundColor,
    background: `linear-gradient(to right, ${gradientColor}, ${backgroundColor})`,
    clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 ${slant}%)`,
  }),
);

export interface IDiagonalHeroProps extends IDiagonal {}

export const DiagonalHero: React.SFC<IDiagonalHeroProps> = ({
  slant,
  height,
  backgroundColor,
  gradientColor,
}) => (
  <Hero height={height}>
    <Diagonal
      height={height}
      slant={slant}
      backgroundColor={backgroundColor}
      gradientColor={gradientColor}
    />
    <FallingStars height={height} />
  </Hero>
);

DiagonalHero.displayName = "DiagonalHero";
