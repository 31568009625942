import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { colors } from "../../common/utilities";
import { IconType } from "react-icons";

export interface ISkill {
  name: string;
  icon: JSX.Element;
}

const SkillsWrapper = styled.div({
  textAlign: "center",
});

const SectionHeader = styled.h2({
  paddingBottom: 16,
  borderBottom: `2px solid ${colors.grey.base}`,
});

const SkillList = styled.div({
  display: "flex",
  justifyContent: "space-around",
  flexWrap: "wrap",
});

const SkillItem = styled.div({
  padding: 16,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "default",
  transition: `transform 800ms`,
  "&:hover": {
    transform: `scale(1.25)`,
  },
});

const IconWrapper = styled.div({
  lineHeight: 1,
  width: 34,
  height: 34,
  marginRight: 8,
  svg: {
    width: 34,
    height: 34,
    color: colors.green.base,
  },
});

const SkillTitle = styled.div({
  // color: colors.grey.dark,
});

interface ISkillsProps {
  title: string;
  skills: ISkill[];
}

export const Skills: React.SFC<ISkillsProps> = ({ title, skills }) => (
  <SkillsWrapper>
    <SectionHeader>{title}</SectionHeader>
    <SkillList>
      {skills.map(({ name, icon }, i) => (
        <SkillItem key={i}>
          <IconWrapper>{icon}</IconWrapper>

          <SkillTitle>{name}</SkillTitle>
        </SkillItem>
      ))}
    </SkillList>
  </SkillsWrapper>
);

Skills.displayName = "Skills";
