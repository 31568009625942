interface IColor {
  light: string;
  base: string;
  dark: string;
}

interface IColorBright extends IColor {
  bright: string;
}

interface IColors {
  grey: IColorBright;
  white: string;
  black: string;
  green: IColorBright;
}

export const colors: IColors = {
  grey: {
    base: "#e6e7e9",
    light: "#adadad",
    dark: "#3d3e42",
    bright: "#9099a0",
  },
  white: "#ffffff",
  black: "#000000",
  green: {
    base: "#0A4E00",
    dark: "#073000",
    light: "#1E6F11",
    bright: "#66cc99",
  },
};

// const breakpoints = [576, 768, 992, 1200];
const breakpoints = [576, 768, 1050, 1400];
export const mediaQuery = breakpoints.map(bp => `@media (min-width: ${bp}px)`);

export const getRandomNumberBetween = (start: number, end: number) =>
  Math.floor(Math.random() * end) + start;
