import React from "react";
import { getRandomNumberBetween } from "../../utilities";

interface IAutoTypeProps {
  staticText: string;
  typeText: string[];
}

interface IAutoTypeState {
  activeIndex: number;
  activeText: string;
}

export class AutoType extends React.Component<IAutoTypeProps, IAutoTypeState> {
  state = {
    activeIndex: 0,
    activeText: "",
  };

  componentDidMount() {
    this.startTyping();
  }

  startTyping = () => {
    const { typeText } = this.props;
    if (typeText && typeText.length) {
      this.typeWord(typeText[0]);
    }
  };

  typeWord = (word: string) => {
    const { activeText } = this.state;
    const remaining = word.substring(activeText.length).split("");
    if (remaining.length) {
      // Add next character
      this.setState(prevState => ({
        activeText: prevState.activeText + remaining[0],
        activeIndex: prevState.activeIndex,
      }));
      setTimeout(() => this.typeWord(word), getRandomNumberBetween(75, 125));
    } else {
      // backspace the word after a delay
      setTimeout(() => this.backspaceActiveWord(), 2000);
    }
  };

  backspaceActiveWord = () => {
    const { activeText, activeIndex } = this.state;
    const { typeText } = this.props;
    const remaining = activeText.split("");
    const newText = activeText.substring(0, activeText.length - 1);
    if (remaining.length) {
      // Remove last character
      this.setState({
        activeIndex,
        activeText: newText,
      });
      setTimeout(this.backspaceActiveWord, getRandomNumberBetween(30, 60));
    } else {
      // Start typing next word, or restart from beginning
      if (activeIndex + 1 < typeText.length) {
        this.typeWord(typeText[activeIndex + 1]);
        this.setState({
          activeIndex: activeIndex + 1,
          activeText: "",
        });
      } else {
        this.typeWord(typeText[0]);
        this.setState({
          activeIndex: 0,
          activeText: "",
        });
      }
    }
  };

  render() {
    const { staticText } = this.props;
    const { activeText } = this.state;

    return this.state ? <span>{`${staticText}${activeText}`}</span> : null;
  }
}
