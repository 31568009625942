import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Wrapper } from "../../common/components/wrapper";
import { colors } from "../../common/utilities";
import { Link } from "../../common/components/link";

export interface INavigationItem {
  name: string;
  link?: string;
  externalLink?: string;
}

export interface INavigationProps {
  items: INavigationItem[];
}

const NavigationWrapper = styled.div({
  position: "relative",
});

const MenuItem = styled.div({
  color: colors.grey.dark,
  padding: 8,
  fontWeight: "bold",
  wordBreak: "break-all",
});

export const Navigation: React.SFC<INavigationProps> = ({ items }) => {
  return (
    <NavigationWrapper>
      <Wrapper css={{ position: "relative" }}>
        {items.map((item, i) => (
          <MenuItem key={i}>
            {item.link ? (
              <Link to={item.link}>{item.name}</Link>
            ) : item.externalLink ? (
              <a href={item.externalLink} target="_blank">
                {item.name}
              </a>
            ) : (
              item.name
            )}
          </MenuItem>
        ))}
      </Wrapper>
    </NavigationWrapper>
  );
};
