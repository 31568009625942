import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Wrapper } from "../common/components/wrapper";
import { SEO } from "../common/components/seo";
import { DiagonalHero } from "../components/diagonal-hero";
import { AutoType } from "../common/components/autotype";
import styled from "@emotion/styled";
import { colors } from "../common/utilities";
import {
  DiAndroid,
  DiJavascript,
  DiDotnet,
  DiHtml5,
  DiCss3,
  DiPhp,
  DiRuby,
  DiJava,
  DiMysql,
  DiSqllite,
  DiDatabase,
  DiCodeBadge,
  DiNodejs,
  DiNodejsSmall,
  DiReact,
  DiAngularSimple,
  DiJqueryLogo,
  DiSass,
  DiBootstrap,
  DiIonic,
  DiGrails,
  DiRubyRough,
  DiGit,
  DiPostgresql,
  DiMongodb,
  DiWordpress,
  DiJsBadge,
  DiUnitySmall,
  DiAtlassian,
  DiJenkins,
  DiDocker,
  DiRor,
  DiCode,
  DiJavascript1,
  DiGoogleAnalytics,
  DiGitBranch,
  DiGitCommit,
} from "react-icons/di";
import { ISkill, Skills } from "../components/skills";
import { Timeline, ITimelineItem } from "../components/timeline";

const typeText: string[] = [
  "a software developer",
  "logical",
  "a friend",
  "clever",
  "a teammate",
  "responsible",
  "a problem solver",
  "always learning",
];

const languages: ISkill[] = [
  {
    name: "Javascript",
    icon: <DiJavascript1 />,
  },
  {
    name: "C#",
    icon: <DiDotnet />,
  },
  {
    name: "HTML",
    icon: <DiHtml5 />,
  },
  {
    name: "CSS",
    icon: <DiCss3 />,
  },
  {
    name: "PHP",
    icon: <DiPhp />,
  },
  {
    name: "Ruby",
    icon: <DiRuby />,
  },
  {
    name: "Java",
    icon: <DiJava />,
  },
  {
    name: "SQL",
    icon: <DiDatabase />,
  },
  {
    name: "C++",
    icon: <DiCodeBadge />,
  },
];

const frameworks: ISkill[] = [
  {
    name: "Node",
    icon: <DiNodejsSmall />,
  },
  {
    name: "React",
    icon: <DiReact />,
  },
  {
    name: "Redux",
    icon: <DiCode />,
  },

  {
    name: "jQuery",
    icon: <DiJqueryLogo />,
  },
  {
    name: ".NET Framework & .NET Core",
    icon: <DiDotnet />,
  },
  {
    name: "SASS",
    icon: <DiSass />,
  },
  {
    name: "Bootstrap",
    icon: <DiBootstrap />,
  },
  {
    name: "Ionic",
    icon: <DiIonic />,
  },
  {
    name: "Angular",
    icon: <DiAngularSimple />,
  },
  {
    name: "Ruby on Rails",
    icon: <DiRor />,
  },
];

const tools: ISkill[] = [
  {
    name: "Git",
    icon: <DiGit />,
  },
  {
    name: "GatsbyJS",
    icon: <DiJsBadge />,
  },
  {
    name: "Unity",
    icon: <DiUnitySmall />,
  },
  {
    name: "Jenkins",
    icon: <DiJenkins />,
  },
  {
    name: "Docker",
    icon: <DiDocker />,
  },
  {
    name: "Kubernetes",
    icon: <DiGitCommit />,
  },
  {
    name: "MySql & MariaDB",
    icon: <DiMysql />,
  },
  {
    name: "PostgreSQL",
    icon: <DiPostgresql />,
  },
  {
    name: "MongoDB",
    icon: <DiMongodb />,
  },
  {
    name: "Android",
    icon: <DiAndroid />,
  },
  {
    name: "Wordpress",
    icon: <DiWordpress />,
  },
  {
    name: "Google Analytics",
    icon: <DiGoogleAnalytics />,
  },
  {
    name: "Netlify",
    icon: <DiGitBranch />,
  },
  {
    name: "Jira & Bitbucket",
    icon: <DiAtlassian />,
  },
];

const workHistory: ITimelineItem[] = [
  {
    name: "iHerb",
    date: "Current",
    location: "Irvine, CA",
    title: "Software Developer I",
    link: "https://www.iherb.com/",
    highlights: [
      "Actively maintains and develops the front-end code bases (modern and legacy) for a large portion of the public pages on iHerb’s international e-commerce site (iherb.com).",
      "Works with a small team of back-end and front-end developers to implement new features with time sensitive deadlines and fix problems immediately when they affect the production environment.",
      "Researched and implemented many SEO related optimizations, including a dynamic sitemap and micro-data.",
    ],
  },
  {
    name: "David Riley Associates",
    date: "June 2017",
    location: "Corona Del Mar, CA",
    title: "Web Developer",
    link: "http://www.rileydra.com/",
    highlights: [
      "Worked closely with design teams to come up with a beautiful and functional website that matches a clients brand, values, and goals.",
      "Communicated with clients to obtain project vision, address any problems during development, and to present finished work. Additionally provided training on how to use any unfamiliar systems to clients.",
      "Decided on technologies that need to be implemented for all new projects, including content management needs and 3rd party data connections.",
      "Maintained existing projects through backups and updates, as well as the companies own internal network and servers. This also included general office troubleshooting of phones, printers, and email.",
    ],
  },
  {
    name: "Worship Resource Media",
    date: "June 2016",
    location: "La Mirada, CA",
    title: "Web Developer",
    link: "https://www.worshipbetter.com/",
    highlights: [
      "Worked closely with a designer to implement wire-frame designs into functional web pages primarily utilizing React.js, HTML5, and CSS (SASS) on their primary website (WorshipBetter.com).",
      "Built Ruby on Rails controllers that interfaced with data models in order to highlight meaningful data.",
      "Developed a system to parse and recognize large portions of lyrical music data and compare it to multiple sources to add to our collected knowledge base.",
      "Participated regularly in Agile style system design and user interface meetings to contribute ideas and assign tasks in order to keep the project moving in the right direction.",
    ],
  },
  {
    name: "Chronicle Technologies",
    date: "July 2014",
    location: "CA",
    title: "Software Developer",
    link: "https://www.gochronicle.com/",
    highlights: [
      "Implemented and maintained over four third party API integrations to import and export customer data regarding equipment and cost estimates through a server side .NET Windows Forms Application.",
      "Worked with two separate outside contractors to assist development of Chronicle's Android application, including the addition of push notifications, and SMS communications to create a unified system.",
      "Worked with customers and support teams to directly address and resolve problems related to integrations I had worked on or portions of our system I was familiar with.",
      "Responsible for resolving problems in legacy code as well as making it more efficient and extendable.",
    ],
  },
];

const Subtitle = styled.h3({
  color: colors.grey.dark,
});

const SectionWrapper = styled.div({
  marginTop: 64,
  "&:last-child": {
    marginBottom: 64,
  },
});

export interface IIndexPageProps {
  data: IIndexPageQuery;
}

const IndexPage: React.SFC<IIndexPageProps> = ({ data }) => {
  return (
    <Layout resumeUrl={data.resume.publicURL}>
      <SEO title="Portfolio" />
      <DiagonalHero
        slant={70}
        height={300}
        backgroundColor={colors.black}
        gradientColor={colors.green.base}
      />
      <Wrapper>
        <h1>Brayden Stewart</h1>
        <Subtitle>
          <AutoType typeText={typeText} staticText="I am " />
        </Subtitle>
        <SectionWrapper>
          <Skills title="Languages" skills={languages} />
        </SectionWrapper>
        <SectionWrapper>
          <Skills title="Frameworks & Libraries" skills={frameworks} />
        </SectionWrapper>
        <SectionWrapper>
          <Skills title="Platforms & Tools" skills={tools} />
        </SectionWrapper>
      </Wrapper>
      <Timeline items={workHistory} />
      {/* Education */}
      {/* Noteable projects */}
      {/* https://codepen.io/rayfranco/pen/Llvbt */}
      {/* https://codepen.io/YusukeNakaya/pen/LgNJQx */}
    </Layout>
  );
};

export default IndexPage;

interface IIndexPageQuery {
  resume: {
    publicURL: string;
  };
}

export const query = graphql`
  query {
    resume: file(relativePath: { eq: "files/resume.pdf" }) {
      publicURL
    }
  }
`;
