import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { mediaQuery, colors } from "../../common/utilities";
import { FallingStars } from "../falling-stars";
import { Link } from "../../common/components/link";

const TimelineWrapper = styled.div({
  position: "relative",
  width: "100%",
  marginTop: 20,
  zIndex: 1000,
  [mediaQuery[2]]: {
    width: 1000,
    margin: "0 auto",
    marginTop: "20px",
  },
  // Vertical Line
  "&:before": {
    position: "absolute",
    zIndex: 5,
    left: "50%",
    top: "0",
    content: '" "',
    display: "block",
    width: "2px",
    height: "100%",
    marginLeft: "-1px",
    background: colors.grey.bright,
  },
});

const TimelineItemWrapper = styled.div<IDirection>(({ left }) => ({
  paddingTop: 32,
  paddingBottom: 32,
  position: "relative",
  zIndex: 10,
  display: "flex",
  justifyContent: left ? "flex-start" : "flex-end",
  [mediaQuery[2]]: {
    paddingTop: 8,
    paddingBottom: 8,
  },
}));

const HexBullet = styled.span<IDirection>(({ left }) => ({
  width: "16px",
  height: "10px",
  position: "absolute",
  background: colors.green.light,
  zIndex: 5,
  left: "0",
  right: "0",
  marginLeft: "auto",
  marginRight: "auto",
  top: "-34px",
  marginTop: "0",
  border: "0px",
  textAlign: "center",
  fontSize: "22px",
  "&:before": {
    borderBottom: `4px solid ${colors.green.light}`,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    position: "absolute",
    content: '" "',
    top: "-4px",
    left: "0",
    width: "0",
    height: "0",
  },
  "&:after": {
    borderTop: `4px solid ${colors.green.light}`,
    borderLeft: "8px solid transparent",
    borderRight: "8px solid transparent",
    content: '" "',
    position: "absolute",
    bottom: "-4px",
    left: "0",
    width: "0",
    height: "0",
  },
  [mediaQuery[2]]: {
    margin: 0,
    top: 12,
    left: left ? "auto" : -44,
    right: left ? -44 : "auto",
  },
}));

interface IDirection {
  left?: boolean;
}

const Direction = styled.div<IDirection>(({ left }) => ({
  width: "100%",
  textAlign: "center",
  backgroundColor: colors.white,
  padding: 16,
  borderRadius: 16,
  boxShadow: `0 0 15px ${colors.green.bright}`,
  maxWidth: 800,
  margin: "auto",
  transition: `box-shadow 400ms`,
  [mediaQuery[2]]: {
    position: "relative",
    width: 480,
    textAlign: left ? "right" : "left",
    margin: 0,
  },
  "&:hover": {
    boxShadow: `0 0 40px ${colors.green.bright}`,
  },
}));

const FlagWrapper = styled.div({
  textAlign: "center",
  position: "relative",
  paddingBottom: 8,
  marginBottom: 8,
  borderBottom: `1px solid ${colors.grey.base}`,
});

const Flag = styled.span({
  position: "relative",
  display: "block",
  fontWeight: 600,
  zIndex: 15,
  color: colors.black,
});

const TimeWrapper = styled.span({
  display: "block",
  position: "relative",
  margin: 0,
  zIndex: 14,
  lineHeight: "1em",
  verticalAlign: "middle",
  color: colors.white,
  [mediaQuery[2]]: {
    display: "inline-block",
  },
});

const Time = styled.span({
  background: colors.green.light,
  padding: "8px",
  display: "block",
});

const Description = styled.div({
  position: "relative",
  zIndex: 15,
});

const FlexFlag = styled.div<IDirection>(({ left }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  [mediaQuery[2]]: {
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

const FullWrapper = styled.div({
  backgroundColor: colors.black,
  padding: 16,
  position: "relative",
});

const Highlights = styled.ul({
  textAlign: "left",

  li: {
    marginTop: 8,
  },
});

export interface ITimelineItem {
  name: string;
  date: string;
  title: string;
  location: string;
  link: string;
  highlights: string[];
}

export interface ITimelineProps {
  items: ITimelineItem[];
}
// https://codepen.io/krishnab/pen/OPwqbW/
export const Timeline: React.SFC<ITimelineProps> = ({ items }) => (
  <FullWrapper>
    <FallingStars full speed={500} rise />
    <TimelineWrapper>
      {items.map((ti, i) => {
        const isLeft = !(i % 2 == 0 || i == 0);
        return (
          <TimelineItemWrapper left={isLeft}>
            <Direction left={isLeft}>
              <Link
                to={ti.link}
                cssStyle={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 20,
                }}
              />
              <FlagWrapper>
                <HexBullet left={isLeft} />
                <FlexFlag left={isLeft}>
                  <Flag>{ti.name}</Flag>
                  <TimeWrapper>
                    <Time>{ti.date}</Time>
                  </TimeWrapper>
                </FlexFlag>
              </FlagWrapper>
              <Description>
                <Highlights>
                  {ti.highlights.map((h, i) => (
                    <li key={i}>{h}</li>
                  ))}
                </Highlights>
              </Description>
            </Direction>
          </TimelineItemWrapper>
        );
      })}
    </TimelineWrapper>
  </FullWrapper>
);

Timeline.displayName = "Timeline";
