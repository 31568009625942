import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "normalize.css";
import "./layout.css";
import { Header } from "../header";
import { Footer } from "../footer";

export interface ILayoutProps {
  resumeUrl: string;
}

const Layout: React.SFC<ILayoutProps> = ({ children, resumeUrl }) => (
  <>
    <Header resumeUrl={resumeUrl} />
    <main>{children}</main>
    <Footer resumeUrl={resumeUrl} />
  </>
);

export default Layout;
