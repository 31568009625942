import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { colors, mediaQuery } from "../../common/utilities";
import { ButtonWrapper } from "../../common/components/button";
import { Wrapper } from "../../common/components/wrapper";
import {
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoGithub,
  IoLogoBitbucket,
  IoMdMail,
  IoMdPaper,
} from "react-icons/io";
import { Link } from "../../common/components/link";

const HeaderWrapper = styled.header({
  backgroundColor: colors.black,
  color: colors.white,
});

const FlexWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

const SocialMedia = styled.div({
  whiteSpace: "nowrap",
  overflow: "hidden",
  alignItems: "center",
  display: "flex",
  ["a,svg"]: {
    height: 30,
    width: 30,
  },
  svg: {
    fill: colors.white,
    transition: "fill 250ms, color 250ms",
  },
  a: {
    marginLeft: 8,
    marginRight: 8,
    display: "inline-block",
    cursor: "pointer",
    "&:hover svg": {
      transition: "fill 250ms, color 250ms",
      fill: colors.grey.light,
    },
  },
});

const Email = styled.a({
  marginRight: 16,
});

const ContactButtons = styled.div({
  display: "none",
  [mediaQuery[1]]: {
    display: "block",
  },
});

const ContactIcons = styled.div({
  display: "inline-block",
  [mediaQuery[1]]: {
    display: "none",
  },
});

interface IHeaderProps {
  resumeUrl: string;
}

export const Header: React.SFC<IHeaderProps> = ({ resumeUrl }) => (
  <HeaderWrapper>
    <Wrapper>
      <FlexWrapper>
        <SocialMedia>
          <ContactIcons>
            <a href="mailto:brayden.m.stewart@gmail.com">
              <IoMdMail />
            </a>
            <a href={resumeUrl} target="_blank">
              <IoMdPaper />
            </a>
          </ContactIcons>
          <Link to="https://twitter.com/Braydito">
            <IoLogoTwitter />
          </Link>
          <Link to="https://www.linkedin.com/in/braydenstewart11/">
            <IoLogoLinkedin />
          </Link>
          <Link to="https://github.com/B-Stewart">
            <IoLogoGithub />
          </Link>
          <Link to="https://bitbucket.org/B-Stewart/">
            <IoLogoBitbucket />
          </Link>
        </SocialMedia>
        <ContactButtons>
          <Email href="mailto:brayden.m.stewart@gmail.com">
            Brayden.m.Stewart@gmail.com
          </Email>
          <ButtonWrapper.Primary>
            <a href={resumeUrl} target="_blank">
              Resume (PDF)
            </a>
          </ButtonWrapper.Primary>
        </ContactButtons>
      </FlexWrapper>
    </Wrapper>
  </HeaderWrapper>
);

Header.displayName = "Header";
